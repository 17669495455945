<template>
    <div class="btn-group">
        <template v-for="option in options" :key="option[option_value]">
            <label
                class="btn radio-label"
                :class="{
                    'btn-outline-secondary': (checked !== Number(option[option_value])), 'btn-secondary': (checked === Number(option[option_value])),
                }"
            >
                <input
                    type="radio"
                    :name="input_name"
                    :value="Number(option[option_value])"
                    v-model="checked"
                    @change="$emit('update:modelValue', checked);"
                    :required=required
                    class="btn-check radio-input"
                    autocomplete="off"
                >
                {{ option[option_label] }}
            </label>
        </template>
    </div>
</template>

<script>
import { getCurrentInstance } from 'vue';
export default {
    name: 'FormRadio',
    props: {
        modelValue: {
        },
        options: {
            type: Array,
        },
        option_value: {
            type: String,
            default: 'value'
        },
        option_label: {
            type: String,
            default: 'label'
        },
        required: {
            type: Boolean,
            default: false
        },
    },
    emits: [
        'update:modelValue',
    ],
    data() {
        return {
            checked: this.modelValue
        }
    },
    setup() {
        //inputにユニークなname値を自動で採番するためuidを利用（name値がないとform validationできないため）
        let input_name = getCurrentInstance().uid;
        return {
            input_name,
        }
    },
    watch: {

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
