import Enum from './enum'

/**
 * インポートファイル種別
 */
class ImportType extends Enum {
    static WORK = 1;
    static PROJECT = 2;
    static USER = 3;

    static values() {
        return {
            [this.WORK]: "稼働明細",
            [this.PROJECT]: "案件マスタ",
            [this.USER]: "ユーザマスタ",
        }
    }
}

export default ImportType;
