<template>
    <header class="page-title">
        <h1><i class="bi bi-upload"></i> インポート</h1>
        <router-link class="btn btn-outline-primary" :to="{name: 'ImportHistory'}">インポート履歴</router-link>
    </header>

    <section class="section">
        <i class="bi bi-question-circle text-primary"></i> インポートの仕様は <a href="https://github.com/ad5-inc/hitotsuki-guide/blob/master/src/pages/901-import.md" target="_blank">ヘルプ</a> をご確認ください。
    </section>

    <section class="section">
        <div class="mb-3">
            <label class="form-label">インポートするファイルの種類</label>
            <div>
                <form-radio v-model="import_type" :options="ImportType.options()"></form-radio>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label">CSVファイル</label>
            <div>
                <input type="file" @change="selectFile" accept="text/csv">
            </div>
        </div>

        <div class="mb-3" v-if="import_type === ImportType.PROJECT">
            <label class="form-label">未登録の顧客</label>
            <div>
                <form-radio v-model="condition.handle_customer" :options="handle_type"></form-radio>
            </div>
        </div>
        <div class="mb-3" v-if="import_type === ImportType.PROJECT">
            <label class="form-label">未登録の案件分類</label>
            <div>
                <form-radio v-model="condition.handle_project_category" :options="handle_type"></form-radio>
            </div>
        </div>
        <div class="mb-3" v-if="import_type === ImportType.USER || import_type === ImportType.PROJECT">
            <label class="form-label">未登録の部門</label>
            <div>
                <form-radio v-model="condition.handle_department" :options="handle_type"></form-radio>
            </div>
        </div>
        <div class="mb-3" v-if="import_type === ImportType.PROJECT || import_type === ImportType.WORK">
            <label class="form-label">未登録のユーザ</label>
            <div>
                <form-radio v-model="condition.handle_user" :options="handle_type"></form-radio>
            </div>
        </div>
        <div class="mb-3" v-if="import_type === ImportType.WORK">
            <label class="form-label">未登録の案件</label>
            <div>
                <form-radio v-model="condition.handle_project" :options="handle_type"></form-radio>
            </div>
        </div>
        <div class="mb-3" v-if="import_type === ImportType.WORK">
            <label class="form-label">未登録のフェーズ</label>
            <div>
                <form-radio v-model="condition.handle_phase" :options="handle_type"></form-radio>
            </div>
        </div>
        <div class="mb-3" v-if="import_type === ImportType.WORK">
            <label class="form-label">未登録の作業カテゴリ</label>
            <div>
                <form-radio v-model="condition.handle_work_category" :options="handle_type"></form-radio>
            </div>
        </div>

        <div class="mt-5">
            <button class="btn btn-primary" @click="upload">インポート</button>
        </div>
    </section>
</template>

<script>
import FormRadio from '@/components/forms/FormRadio';
//import Import from "@/models/entities/import";
import ImportType from '@/models/enums/import-type';

export default {
    name: 'ImportHistory',
    components: {
        FormRadio
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            import_type: ImportType.WORK,
            csv: null,
            condition: {
                handle_department: 1,
                handle_customer: 1,
                handle_project_category: 1,
                handle_user: 1,
                handle_project: 1,
                handle_phase: 1,
                handle_work_category: 1,
            },

            handle_type: [
                {value: 1, label: "エラーとする"},
                {value: 2, label: "自動作成"},
            ],
            ImportType,
        }
    },
    mounted() {

    },
    methods: {
        selectFile(e) {
            e.preventDefault();
            let files = e.target.files;
            if (files.length > 0) {
                this.csv = files[0];
            } else {
                this.csv = null;
            }
        },
        upload() {
            if (this.csv === null) {
                this.showErrorMessage('CSVファイルが選択されていません');
                return;
            }

            let form_data = new FormData();
            form_data.append('csv', this.csv);
            form_data.append('import_type', this.import_type);
            Object.keys(this.condition).forEach((key) => {
                form_data.append('condition[' + key + ']', this.condition[key]);
            })

            let config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            this.startScreenLoading();

            this.$http.post('/import', form_data, config)
            .then(() => {
                this.showMessage('インポートを予約しました');
                this.$router.push({name: 'ImportHistory'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    },
}
</script>

<style scoped>
</style>
